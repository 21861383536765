@import url("https://fonts.cdnfonts.com/css/sf-ui-text-2");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "SF UI Text", sans-serif;
}

@media screen and (min-width: 1200px) {
  #company-statement,
  #about-us,
  #our-services,
  #operation-hours {
    scroll-margin-top: 7.3rem;
  }
}

@media screen and (max-width: 1200px) {
  #company-statement,
  #about-us,
  #our-services,
  #operation-hours {
    scroll-margin-top: 100px;
  }
}

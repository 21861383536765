@media screen and (max-width: 1200px) {
  .home-sec-title-text {
    font-size: 8vw !important;
  }
  .about-us-title,
  .service-title,
  .drilling-rig-title,
  .customer-title {
    font-size: 5vw !important;
  }
}

@media screen and (max-width: 768px) {
  .home-sec-title-text {
    font-size: 12vw !important;
  }
  .about-us-title,
  .service-title,
  .drilling-rig-title,
  .customer-title {
    font-size: 8vw !important;
  }
}

@media screen and (max-width: 576px) {
  .home-sec-title-text {
    font-size: 18vw !important;
  }
  .about-us-title,
  .service-title,
  .drilling-rig-title,
  .customer-title {
    font-size: 10vw !important;
  }
}

.service-desc-card {
  width: 100%;
  position: absolute !important;
  background-color: #2b71b9 !important;
  bottom: 0;
  left: 0;
  right: 0;
  transition: height 0.3s ease;
  padding: "0 10px";
  height: 5rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-card:hover > .service-desc-card {
  background-color: #f21a27 !important;
  cursor: pointer;
  height: 6rem;

  .service-desc-arrow {
    display: block !important;
  }
}

.service-desc-arrow {
  display: none !important;
  transition: display 0.3s ease;
}
